import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import '../css/common.css'



class ContactForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            name: 'enquiry',
            phone: '',
            email: '',
            company: '',
            field: '',
            mode:'',
            message:'',
            errorEmail: '',
            errorPhone: '',
            errorCaptcha: '',
            errorField: '',
            dataValue: false,
            verified: false,
            captcha : `${process.env.CAPTCHA_SITE_KEY}`

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange = (event) => {
       this.setState({ [event.target.name]: event.target.value });
        if(event.target.name === 'email'){
            const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
            }else if(pattern){
                this.setState({ errorEmail: '', dataValue: false });
            }
        }

        if(event.target.name === 'phone'){
            const pattern = /^[0-9-+#*]*$/;
            if (!pattern.test(event.target.value)) {
                this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
            }else if(pattern){
                this.setState({ errorPhone: '', dataValue: false });
            }
        }
    }

    onChange = (value) => {
        this.setState({
            verified: true
        })
    };
   
    
    handleSubmit = (event) => { 
        const {field} = this.state;
        if(field === '' || field=== 'Intrested In'){
            this.setState({ errorField: 'Plese Select field of interest', dataValue: false });
        }else if (!this.state.verified) {
            this.setState({ errorCaptcha: 'Please verify Captcha', dataValue: false });
        }else if (this.state.errorEmail!=='') {
            this.setState({ errorEmail: 'Please enter valid email address', dataValue: false });
        }else if (this.state.errorPhone!=='') {
            this.setState({ errorPhone: 'Only numbers and Phone Character (#, -, *) allowed', dataValue: false });
        }else {
            fetch(`${process.env.GATSBY_API_URL}/contact`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state)
            }).then(function (response) {
                if(response.status === 200){
                    alert("Email Sent Successfully");
                    window.location.reload();
                }else{
                    alert("Couldn't Send email");
                    window.location.reload();
                }
                
            });
        }
        event.preventDefault();
    }
    render() {
        return (
            <Container className="form">
                <form method="post" onSubmit={this.handleSubmit}>
                 

                        <Form.Group >
                            <Row >
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control
                                        type="text"
                                        value={this.state.fullname}
                                        name="fullname"
                                        onChange={this.handleChange}
                                        className="inputForm"
                                        placeholder="Full Name"
                                        required="required"
                                    />
                                    
                                </Col>
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control
                                        type="text"
                                        className="inputForm"
                                        placeholder="Phone"
                                        value={this.state.phone}
                                        name="phone"
                                        required="required"
                                        onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red", fontSize: "12px" }} id="emailspan">{this.state.errorPhone} </span>
                                </Col>
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control
                                        type="text"
                                        className="inputForm"
                                        placeholder="Email"
                                        value={this.state.email}
                                        required="required"
                                        name="email"
                                        onChange={this.handleChange}
                                    />
                                    <span style={{ color: "red", fontSize: "12px" }} id="emailspan">{this.state.errorEmail} </span>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control
                                        type="text"
                                        className="inputForm"
                                        placeholder="Company"
                                        required="required"
                                        value={this.state.company}
                                        name="company"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control as="select" required="required" className="inputForm" name="field" value={this.state.field} onChange={this.handleChange} >
                                        <option>Interested In</option>
                                        <option value="Hardware Solutions">Hardware Solutions</option>
                                        <option value="Software Solutions">Software Solutions</option>
                                        <option value="Mobile Solutions">Mobile Solutions</option>
                                    </Form.Control>
                                    <span style={{ color: "red", fontSize: "12px" }}>{this.state.errorField}</span>
                                </Col>
                                <Col sm={12} lg={4} className="mt-2">
                                    <Form.Control type="text" 
                                    required="required" 
                                    placeholder="How did you hear about us" 
                                    className="inputForm" 
                                    name="mode" 
                                    value={this.state.mode} 
                                    onChange={this.handleChange} />
                                </Col>
                            </Row>
                            <Row className=" headerformRow1 mt-3 ml-0" >
                                <Form.Control as="textarea" rows={3} placeholder="How can Trace Width help you?" className="inputForm" name="message" value={this.state.message} onChange={this.handleChange} />
                            </Row>
                        </Form.Group>
                    
                   
                    <ReCAPTCHA className="mt-3 ml-0"
                        sitekey={this.state.captcha}
                        onChange={this.onChange}>
                        <span style={{ color: "red", fontSize: "12px" }}>{this.state.verified ? '' :this.state.errorCaptcha}</span></ReCAPTCHA>
                    <Button className="cntButton ml-0" type="submit" value="Submit">
                        MAKE CONTACT
                    </Button>
                </form>
            </Container>

        );
    }
}

export default ContactForm