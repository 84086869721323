import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Laboratory" />
        <div >
            <Image alt="military" filename="laboratory-banner.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead">Laboratory Equipment Design & Supply</h2>
            </Row>
            <hr className="embhr" />
            <Row>
                <Col sm={12} lg={6} className="servicePagePara">
                We are professionals in empowering the scientists and researchers of today and tomorrow with leading edge engineering and technology tools. By expanding your capabilities in technology, Trace Width gives you the key elements you need to maximize project potential, cut costs and lead the field in innovation.
                </Col>
                <Col sm={12} lg={6}>
                    <Image alt="military" filename="laboratory-inside.png"  />
                </Col>
                
               
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

